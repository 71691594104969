<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("call detail records") }}</v-toolbar-title>
      <v-spacer />

      <v-menu left bottom rounded>
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip top>
            <template #activator="{ on: onTooltip }">
              <v-btn
                icon
                v-on="{ ...onMenu, ...onTooltip }"
                :loading="loadingBillingPeriods"
                :disabled="loadingBillingPeriods || billingPeriods.length == 0"
              >
                <v-icon>mdi-cash-clock</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("billing period") }}</span>
          </v-tooltip>
        </template>
        <v-list dense>
          <v-list-item-group v-model="billingPeriod" color="primary">
            <v-list-item
              v-for="(period, i) in billingPeriods"
              :key="i"
              :disabled="billingPeriod != null && period.index == billingPeriod"
            >
              <v-list-item-title>
                {{ period.invoice_text }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <date-picker
            v-on="on"
            v-model="dates"
            :allowedDates="dateAllowed"
            icon
            range
            sort-range
            left
            bottom
            rounded
          />
        </template>
        <span>{{ $t("query period") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :loading="loadingExcel"
            :disabled="loadingExcel"
            @click="downloadExcel"
            icon
          >
            <v-icon>mdi-file-excel</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("download CDR Excel sheet") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="items"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        :custom-filter="customFilter"
        sort-by="call_date"
        sort-desc
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50, 100, 500, 1000],
        }"
        show-menu
        local-storage-name="cdrList"
      >
        <template v-slot:top>
          <v-text-field
            class="mb-4"
            v-model="search"
            append-outer-icon="mdi-magnify"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
          />
        </template>

        <template v-slot:item.unit="{ item }">
          {{ $t(item.unit || "Time") }}
        </template>

        <template v-slot:item.call_date="{ item }">
          <div class="monospace">
            {{ item.call_date ? formatDateTime(item.call_date) : "-" }}
          </div>
        </template>

        <template v-slot:item.call_direction="{ item }">
          <v-icon v-if="item.call_direction == 'OUT'" color="red">
            mdi-phone-outgoing
          </v-icon>
          <v-icon v-else color="green">mdi-phone-incoming</v-icon>
        </template>

        <template v-slot:item.duration="{ item }">
          <div
            class="monospace"
            v-if="item.unit == null || item.unit == 'Time'"
          >
            {{ item.duration != null ? formatDuration(item.duration) : "-" }}
          </div>
          <div class="monospace" v-else-if="item.unit == 'DataVolume'">
            {{
              item.duration != null
                ? formatBytes(item.duration, (format = true), (base = 1000))
                : "-"
            }}
          </div>
          <div class="monospace" v-else>
            {{ item.duration }}
          </div>
        </template>

        <template v-slot:item.called_station="{ item }">
          <div class="monospace">{{ item.called_station || "-" }}</div>
        </template>

        <template v-slot:item.calling_station="{ item }">
          <div class="monospace">{{ item.calling_station || "-" }}</div>
        </template>

        <template v-slot:item.amount_with_tax="{ item }">
          <div class="monospace">
            {{
              item.amount_with_tax
                ? formatMoney(item.amount_with_tax, "CHF")
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.includes_tax="{ item }">
          <boolean-value :value="item.includes_tax" colored />
        </template>

        <template v-slot:item.seconds_free_flatrateng_status="{ item }">
          <div class="monospace">
            {{
              item.seconds_free_flatrateng_status != null
                ? formatDuration(item.seconds_free_flatrateng_status)
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.seconds_non_free="{ item }">
          <div class="monospace">
            {{
              item.seconds_non_free != null
                ? formatDuration(item.seconds_non_free)
                : "-"
            }}
          </div>
        </template>
      </data-table-extended>
    </v-card-text>

    <v-card-text v-if="totalIn || totalOut">
      <v-card>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>
            {{ $t("summary of calls and costs") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" colspan="2">
                    <v-icon color="red" small> mdi-phone-outgoing </v-icon>
                    <span class="ml-2">{{ $t("outgoing calls") }}</span>
                  </th>
                  <th class="text-left" colspan="2">
                    <v-icon color="green" small>mdi-phone-incoming</v-icon>
                    <span class="ml-2">{{ $t("incoming calls") }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-icon small>mdi-pound</v-icon>
                    <span class="ml-2">{{ $t("quantity calls") }}</span>
                  </td>
                  <td class="text-right">
                    <div class="monospace">
                      {{
                        totalOut && totalOut.quantity != null
                          ? totalOut.quantity
                          : "-"
                      }}
                    </div>
                  </td>
                  <td>
                    <v-icon small>mdi-pound</v-icon>
                    <span class="ml-2">{{ $t("quantity calls") }}</span>
                  </td>
                  <td class="text-right">
                    <div class="monospace">
                      {{
                        totalIn && totalIn.quantity != null
                          ? totalIn.quantity
                          : "-"
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-icon small>mdi-clock-outline</v-icon>
                    <span class="ml-2">{{ $t("connection duration") }}</span>
                  </td>
                  <td class="text-right">
                    {{
                      totalOut && totalOut.duration != null
                        ? formatDuration(totalOut.duration)
                        : "-"
                    }}
                  </td>
                  <td>
                    <v-icon small>mdi-clock-outline</v-icon>
                    <span class="ml-2">{{ $t("connection duration") }}</span>
                  </td>
                  <td class="text-right">
                    {{
                      totalIn && totalIn.duration != null
                        ? formatDuration(totalIn.duration)
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-icon small>mdi-cash</v-icon>
                    <span class="ml-2">{{ $t("costs excl. VAT") }}</span>
                  </td>
                  <td class="text-right">
                    {{
                      totalOut && totalOut.amount_without_tax != null
                        ? formatMoney(totalOut.amount_without_tax, "CHF")
                        : "-"
                    }}
                  </td>
                  <td>
                    <v-icon small>mdi-cash</v-icon>
                    <span class="ml-2">{{ $t("costs excl. VAT") }}</span>
                  </td>
                  <td class="text-right">
                    {{
                      totalIn && totalIn.amount_without_tax != null
                        ? formatMoney(totalIn.amount_without_tax, "CHF")
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-icon small>mdi-cash</v-icon>
                    <span class="ml-2">{{ $t("costs incl. VAT") }}</span>
                  </td>
                  <td class="text-right">
                    {{
                      totalOut && totalOut.amount_with_tax != null
                        ? formatMoney(totalOut.amount_with_tax, "CHF")
                        : "-"
                    }}
                  </td>
                  <td>
                    <v-icon small>mdi-cash</v-icon>
                    <span class="ml-2">{{ $t("costs incl. VAT") }}</span>
                  </td>
                  <td class="text-right">
                    {{
                      totalIn && totalIn.amount_with_tax != null
                        ? formatMoney(totalIn.amount_with_tax, "CHF")
                        : "-"
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import DataTableExtended from "@/components/basics/DataTableExtended";
import DatePicker from "@/components/basics/DatePicker";
import BooleanValue from "@/components/basics/BooleanValue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatDuration from "@/utils/mixins/formatDuration";
import formatMoney from "@/utils/mixins/formatMoney";
import formatBytes from "@/utils/mixins/formatBytes";
import downloadFile from "@/utils/mixins/downloadFile";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "PhoneCdrs",
  components: {
    DataTableExtended,
    DatePicker,
    BooleanValue,
  },
  mixins: [
    showErrors,
    formatDateTime,
    formatDuration,
    formatMoney,
    formatBytes,
    downloadFile,
    isMobile,
  ],
  props: {
    productId: {
      type: [String, Number],
      require: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingExcel: false,
      items: [],
      dates: [
        this.$moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
        this.$moment(new Date()).format("YYYY-MM-DD"),
      ],
      search: "",
      billingPeriods: [],
      billingPeriod: null,
      loadingBillingPeriods: false,
      totals: [],
      loadingTotals: false,
    };
  },
  computed: {
    totalOut() {
      var totals = this.totals.filter(function (item) {
        return item.call_direction == "OUT";
      });
      if (totals.length > 0) return totals[0];
    },
    totalIn() {
      var totals = this.totals.filter(function (item) {
        return item.call_direction == "IN";
      });
      if (totals.length > 0) return totals[0];
    },
    hideUnit() {
      return (
        this.items.filter(function (i) {
          return i.unit != null && i.unit != "";
        }).length == 0
      );
    },
    headers() {
      return [
        {
          text: this.$i18n.t("unit"),
          value: "unit",
          hidden: this.hideUnit,
        },
        {
          text: this.$i18n.t("direction"),
          value: "call_direction",
        },
        {
          text: this.$i18n.t("call date"),
          value: "call_date",
        },
        {
          text: this.$i18n.t("calling station"),
          value: "calling_station",
        },
        {
          text: this.$i18n.t("called station"),
          value: "called_station",
        },
        {
          text: this.$i18n.t("duration"),
          value: "duration",
        },
        {
          text: this.$i18n.t("destination"),
          value: "destination_name",
        },
        {
          text: this.$i18n.t("destination country code"),
          value: "destination_country_code",
        },
        {
          text: this.$i18n.t("source country code"),
          value: "source_country_code",
          hidden: true,
        },
        {
          text: this.$i18n.t("amount incl. VAT"),
          value: "amount_with_tax",
        },
        {
          text: this.$i18n.t("flat rate name"),
          value: "flatrate_name",
          hidden: true,
        },
        {
          text: this.$i18n.t("includes tax"),
          value: "includes_tax",
          hidden: true,
        },
        {
          text: this.$i18n.t("rating status"),
          value: "rating_status",
          hidden: true,
        },
        {
          text: this.$i18n.t("seconds free flatrate"),
          value: "ratiseconds_free_flatrateng_status",
          hidden: true,
        },
        {
          text: this.$i18n.t("seconds non free"),
          value: "seconds_non_free",
          hidden: true,
        },
        {
          text: this.$i18n.t("status"),
          value: "status",
          hidden: true,
        },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  watch: {
    productId: {
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getItems();
          this.getTotals();
          this.getBillingPeriods();
        } else {
          this.items = [];
          this.totals = [];
        }
      },
    },
    billingPeriod(value) {
      this.getItems();
      this.getTotals();
    },
    dates(value) {
      if (value && value.length == 2) {
        this.getItems();
        this.getTotals();
      }
      this.billingPeriod = null;
    },
  },
  methods: {
    dateAllowed(date) {
      const today = this.$moment(new Date()).format("YYYY-MM-DD");
      return (
        this.$moment(today).isSame(date, "day") ||
        this.$moment(date).isBefore(today)
      );
    },
    customFilter(value, search, item) {
      return (
        (item.called_station != null &&
          item.called_station.indexOf(search) >= 0) ||
        (item.calling_station != null &&
          item.calling_station.indexOf(search) >= 0) ||
        (item.destination_name != null &&
          item.destination_name.toLowerCase().indexOf(search.toLowerCase()) >=
            0)
      );
    },
    getBillingPeriods() {
      var that = this;
      this.loadingBillingPeriods = true;
      this.$http
        .get("services/phone/" + this.productId + "/cdr-billing-periods")
        .then((response) => {
          var index = 0;
          that.billingPeriods = response.data.map(function (item) {
            item.index = index;
            index += 1;
            return item;
          });
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingBillingPeriods = false;
        });
    },
    getCdrParams() {
      var params = {};
      if (this.billingPeriod != null)
        params["billing_period_id"] =
          this.billingPeriods[this.billingPeriod].id;
      else {
        params["call_date_after"] =
          this.dates.length > 0
            ? this.$moment(this.dates[0]).format("YYYY-MM-DD")
            : null;
        params["call_date_before"] =
          this.dates.length > 1
            ? this.$moment(this.dates[1]).format("YYYY-MM-DD")
            : null;
      }

      return params;
    },
    getTotals() {
      var that = this;
      this.loadingTotals = true;
      this.$http
        .get("services/phone/" + this.productId + "/cdr-totals", {
          params: this.getCdrParams(),
        })
        .then((response) => {
          that.totals = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingTotals = false;
        });
    },
    getItems() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/phone/" + this.productId + "/cdrs", {
          params: this.getCdrParams(),
        })
        .then((response) => {
          that.items = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    downloadExcel() {
      var that = this;
      this.loadingExcel = true;
      this.downloadFile(
        "services/phone/" + this.productId + "/cdrs",
        "get",
        this.getCdrParams(),
        "cdrs-" +
          this.$moment(this.dates[0]).format("YYYY-MM-DD") +
          "-" +
          this.$moment(this.dates[1]).format("YYYY-MM-DD") +
          ".xlsx",
        "application/vnd.ms-excel",
        function () {
          that.loadingExcel = false;
        },
        null,
        true
      );
    },
  },
};
</script>

<i18n>
{
  "en": {
    "query period": "query period",
    "billing period": "billing period",
    "unit": "Unit",
    "call detail records": "Call detail records",
    "call date": "call date",
    "direction": "direction",
    "calling station": "calling station",
    "called station": "called station",
    "duration": "duration",
    "destination": "destination",
    "amount incl. VAT": "amount incl. VAT",
    "flat rate name": "flat rate name",
    "includes tax": "includes tax",
    "rating status": "rating status",
    "seconds free flatrate": "seconds free flatrate",
    "seconds non free": "seconds non free",
    "status": "status",
    "download CDR Excel sheet": "download CDR Excel sheet",
    "DataVolume": "Data volume",
    "Time": "Time",
    "Pieces": "Pieces",
    "summary of calls and costs": "Summary of calls and costs",
    "outgoing calls": "Outgoing calls",
    "incoming calls": "Incoming calls",
    "quantity calls": "Quantity calls",
    "connection duration": "connection duration",
    "costs excl. VAT": "Costs excl. VAT",
    "costs incl. VAT": "Costs excl. VAT",
    "destination country code": "Destination country ISO code",
    "source country code": "Source country ISO code"
  },
  "de": {
    "query period": "Abfrageperiode",
    "billing period": "Rechnungsperiode",
    "unit": "Einheit",
    "call detail records": "Einzelverbindungsnachweis",
    "call date": "Gesprächszeit",
    "direction": "Richtung",
    "calling station": "Anrufer",
    "called station": "Rufnummer",
    "duration": "Länge",
    "destination": "Ziel",
    "amount incl. VAT": "Betrag mit MwSt.",
    "flat rate name": "Flatrate Name",
    "includes tax": "inkl. MwSt.",
    "rating status": "Rating Status",
    "seconds free flatrate": "Flatrate Frei-Sekunden",
    "seconds non free": "Sekunden verrechnet",
    "status": "Status",
    "download CDR Excel sheet": "CDR Excel Tabelle laden",
    "DataVolume": "Datenvolumen",
    "Time": "Zeit",
    "Pieces": "Anzahl",
    "summary of calls and costs": "Zusammenfassung der Kosten und Gespräche",
    "outgoing calls": "Ausgehende Verbindungen",
    "incoming calls": "Eingehende Verbindungen",
    "quantity calls": "Anzahl Verbindungen",
    "connection duration": "Verbindungsdauer",
    "costs excl. VAT": "Kosten exkl. MwSt.",
    "costs incl. VAT": "Kosten inkl. MwSt.",
    "destination country code": "Ziel Länder ISO Code",
    "source country code": "Anrufer Länder ISO Code"
  }
}
</i18n>
