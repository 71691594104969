var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-progress-linear',{attrs:{"active":_vm.loading || _vm.loadingPhones || _vm.loadingMobiles,"indeterminate":""}}),_c('v-card-text',[(_vm.phones.length > 1 && _vm.isPhone)?_c('v-card',{staticClass:"mt-4"},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("VoIP services")))]),_c('v-spacer'),_c('wiki',{attrs:{"slug":"services-voip-overview"}})],1),_c('v-card-text',_vm._l((_vm.phones),function(phone,index){return _c('v-chip',{key:index,attrs:{"color":"secondary","to":{
            name: 'telephony-service-phone-details',
            params: { productId: _vm.productId, phoneId: phone.id },
          }}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(" mdi-phone-voip ")])],1),_vm._v(" "+_vm._s(phone.name)+" ")],1)}),1)],1):(_vm.details != null && _vm.phones.length == 1 && _vm.isPhone)?_c('phone-base-details',{attrs:{"product-id":_vm.productId,"phone-id":_vm.phones[0].id}}):[(_vm.loadingPhones)?_c('v-card',[_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table-heading, divider, list-item-two-line, table-row-divider@3, table-tfoot"}})],1)],1):_vm._e()],(_vm.mobiles.length > 1)?_c('v-card',{staticClass:"mt-4"},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("mobile services")))]),_c('v-spacer'),_c('wiki',{attrs:{"slug":"services-mobile-overview"}})],1),_c('v-card-text',_vm._l((_vm.mobiles),function(mobile,index){return _c('v-chip',{key:index,attrs:{"color":"secondary","to":{
            name: 'telephony-service-mobile-details',
            params: { productId: _vm.productId, mobileId: mobile.id },
          }}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(" mdi-cellphone ")])],1),_vm._v(" "+_vm._s(mobile.phone_number)+" ")],1)}),1)],1):(_vm.details != null && _vm.mobiles.length == 1)?_c('mobile-phone-details',{attrs:{"product-id":_vm.productId,"mobile-id":_vm.mobiles[0].id,"subscription":_vm.details.subscription}}):[(_vm.loadingMobiles)?_c('v-card',[_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table-heading, divider, table-row-divider@3"}})],1)],1):_vm._e()],(_vm.details != null)?_c('phone-cdrs',{staticClass:"mt-4",attrs:{"product-id":_vm.productId}}):[(_vm.loading)?_c('v-card',[_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table-heading, divider, table-row-divider@3"}})],1)],1):_vm._e()]],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({
          name: 'phone-service',
        })}}},[_vm._v(" "+_vm._s(_vm.$t("telephony subscription list"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }