<template>
  <v-card>
    <v-progress-linear
      :active="loading || loadingPhones || loadingMobiles"
      indeterminate
    />

    <v-card-text>
      <v-card v-if="phones.length > 1 && isPhone" class="mt-4">
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("VoIP services") }}</v-toolbar-title>
          <v-spacer />
          <wiki slug="services-voip-overview" />
        </v-toolbar>
        <v-card-text>
          <v-chip
            v-for="(phone, index) in phones"
            :key="index"
            color="secondary"
            :to="{
              name: 'telephony-service-phone-details',
              params: { productId: productId, phoneId: phone.id },
            }"
          >
            <v-avatar left>
              <v-icon> mdi-phone-voip </v-icon>
            </v-avatar>
            {{ phone.name }}
          </v-chip>
        </v-card-text>
      </v-card>

      <phone-base-details
        v-else-if="details != null && phones.length == 1 && isPhone"
        :product-id="productId"
        :phone-id="phones[0].id"
      />
      <template v-else>
        <!-- Loading animation -->
        <v-card v-if="loadingPhones">
          <v-card-text>
            <v-skeleton-loader
              type="table-heading, divider, list-item-two-line, table-row-divider@3, table-tfoot"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </template>

      <v-card v-if="mobiles.length > 1" class="mt-4">
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("mobile services") }}</v-toolbar-title>
          <v-spacer />
          <wiki slug="services-mobile-overview" />
        </v-toolbar>
        <v-card-text>
          <v-chip
            v-for="(mobile, index) in mobiles"
            :key="index"
            color="secondary"
            :to="{
              name: 'telephony-service-mobile-details',
              params: { productId: productId, mobileId: mobile.id },
            }"
          >
            <v-avatar left>
              <v-icon> mdi-cellphone </v-icon>
            </v-avatar>
            {{ mobile.phone_number }}
          </v-chip>
        </v-card-text>
      </v-card>

      <mobile-phone-details
        v-else-if="details != null && mobiles.length == 1"
        :product-id="productId"
        :mobile-id="mobiles[0].id"
        :subscription="details.subscription"
      />
      <template v-else>
        <!-- Loading animation -->
        <v-card v-if="loadingMobiles">
          <v-card-text>
            <v-skeleton-loader
              type="table-heading, divider, table-row-divider@3"
            />
          </v-card-text>
        </v-card>
      </template>

      <phone-cdrs v-if="details != null" :product-id="productId" class="mt-4" />
      <template v-else>
        <!-- Loading animation -->
        <v-card v-if="loading">
          <v-card-text>
            <v-skeleton-loader
              type="table-heading, divider, table-row-divider@3"
            />
          </v-card-text>
        </v-card>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="
          $router.push({
            name: 'phone-service',
          })
        "
      >
        {{ $t("telephony subscription list") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import showErrors from "@/utils/mixins/showErrors";
import PhoneCdrs from "@/components/services/phone/PhoneCdrs";
import PhoneBaseDetails from "@/components/services/phone/PhoneBaseDetails";
import MobilePhoneDetails from "@/components/services/phone/MobilePhoneDetails";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "PhoneDetails",
  components: {
    PhoneCdrs,
    PhoneBaseDetails,
    MobilePhoneDetails,
    Wiki,
  },
  mixins: [showErrors, formatDate],
  props: {
    productId: {
      type: [String, Number],
      require: true,
    },
  },
  data: () => ({
    loading: false,
    details: null,
    phones: [],
    mobiles: [],
    loadingPhones: false,
    loadingMobiles: false,
    disabling: false,
    enabling: false,
    showDisableDialog: false,
    showEnableDialog: false,
  }),
  watch: {
    productId: {
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getDetails();
          this.getPhones();
          this.getMobiles();
        } else {
          this.details = null;
          this.phones = [];
          this.mobiles = [];
        }
      },
    },
  },
  computed: {
    flatSubscriptionPositions() {
      var that = this;
      var positions = [];
      if (
        that.details &&
        that.details.subscription &&
        that.details.subscription.positions.length > 0
      )
        that.details.subscription.positions.forEach((pos) => {
          pos.level = 0;
          positions.push(pos);
          if (pos.positions.length > 0)
            pos.positions.forEach((pos) => {
              pos.level = 1;
              positions.push(pos);
            });
        });
      return positions;
    },
    isPhone() {
      return (
        this.details &&
        this.details.subscription &&
        this.details.subscription.subject.toLowerCase().indexOf("mobile") < 0
      );
    },
  },
  methods: {
    getDetails() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/phone/" + this.productId, {
          disableDefaultErrorHandler: true,
        })
        .then((response) => {
          that.details = response.data;
        })
        .catch((error) => {
          if (error.status == 404)
            that.$store.commit("setSystemError", {
              msg: that.$t("notFoundMsg"),
              title: that.$t("notFoundTitle"),
            });
          else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getPhones() {
      var that = this;
      this.loadingPhones = true;
      this.$http
        .get("services/phone/" + this.productId + "/phones", {
          disableDefaultErrorHandler: true,
        })
        .then((response) => {
          that.phones = response.data;
        })
        .catch((error) => {
          if (error.status == 404) {
            // do nothing
          } else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loadingPhones = false;
        });
    },
    getMobiles() {
      var that = this;
      this.loadingMobiles = true;
      this.$http
        .get("services/phone/" + this.productId + "/mobiles", {
          disableDefaultErrorHandler: true,
        })
        .then((response) => {
          that.mobiles = response.data;
        })
        .catch((error) => {
          if (error.status == 404) {
            // do nothing
          } else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loadingMobiles = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "status": "Status",
    "termination request until date": "Termination request until date",
    "cancellation date": "Cancellation date",
    "delivery date": "Delivery date",
    "start date": "Start date",
    "creation date": "Creation date",
    "subscription number": "Subscription number",
    "subject": "Name", 
    "subscription": "Subscription",
    "telephony subscription list": "Telephony subscription list",
    "account": "Account",
    "first name": "First name",
    "last name": "Last name",
    "street": "Street",
    "zip": "Zip",
    "city": "City",
    "country": "Country",
    "open product": "open product",
    "subscription positions": "Subscription positions",
    "quantity": "Quantity",
    "description": "Description",
    "yes": "Yes",
    "no": "No",
    "VoIP services": "VoIP services",
    "mobile services": "Mobile services",
    "notFoundMsg": "Telephony service not found in backend, please contact support or wait until setup is done!",
    "notFoundTitle": "Telephony service not found"
  },
  "de": {
    "status": "Status",
    "termination request until date": "Fälligkeitsdatum",
    "cancellation date": "Kündigungsdatum",
    "delivery date": "Aufschaltdatum",
    "start date": "Startdatum",
    "creation date": "Anlegedatum",
    "subscription number": "Abonnement Nummer",
    "subject": "Betreff", 
    "subscription": "Abonnement",
    "telephony subscription list": "Telefonie Abonnement Liste",
    "account": "Konto",
    "first name": "Vorname",
    "last name": "Nachname",
    "street": "Strasse",
    "zip": "PLZ",
    "city": "Stadt",
    "country": "Land",
    "open product": "öffne Produkt",
    "subscription positions": "Abonnement Positionen",
    "quantity": "Menge",
    "description": "Beschreibung",
    "yes": "Ja",
    "no": "Nein",
    "VoIP services": "VoIP Dienste",
    "mobile services": "Mobil Dienste",
    "notFoundMsg": "Telefon Dienst im Backend nicht gefunden, kontaktieren Sie den Support oder warten Sie bis die Einrichtung abgeschlossen ist!",
    "notFoundTitle": "Telefon Dienst nicht gefunden"
  }
}
</i18n>
