/**
 * jacked from: https://github.com/sindresorhus/pretty-bytes
 */

import store from "@/store";

export default {
  methods: {
    formatBytes: function (
      bytes,
      format = false,
      base = 1,
      currencyDisplay = 'symbol',
      useGrouping = true,
      minimumFractionDigits = 2,
      maximumFractionDigits = 2
    ) {
      if (typeof bytes !== 'number' || isNaN(bytes)) {
        throw new TypeError('Expected a number for "bytes"');
      }

      var unit = null;
      var divisor = 1;

      if (base == 1000) {
	      if (bytes > 1000000000000000000000000000) {
          unit = 'RB';
          divisor = 1000000000000000000000000000;
	      } else if (bytes > 1000000000000000000000000) {
          unit = 'RB';
          divisor = 1000000000000000000000000;
	      } else if (bytes > 1000000000000000000000) {
          unit = 'YB';
          divisor = 1000000000000000000000;
        } else if (bytes > 1000000000000000000) {
          unit = 'ZB';
          divisor = 1000000000000000000;
        } else if (bytes > 1000000000000000) {
          unit = 'EB';
          divisor = 1000000000000000;
        } else if (bytes > 1000000000000) {
          unit = 'PB';
          divisor = 1000000000000;
        } else if (bytes > 1000000000) {
          unit = 'TB';
          divisor = 1000000000;
        } else if (bytes > 1000000) {
          unit = 'GB';
          divisor = 1000000;
        } else if (bytes > 1000) {
          unit = 'MB';
          divisor = 1000;
        } else {
          unit = 'kB';
          divisor = 1;
        }
      } else if (base == 1) {
	      if (bytes > 1000000000000000000000000000000) {
          unit = 'QB';
          divisor = 1000000000000000000000000000000;
	      } else if (bytes > 1000000000000000000000000000) {
          unit = 'RB';
          divisor = 1000000000000000000000000000;
        } else if (bytes > 1000000000000000000000000) {
          unit = 'YB';
          divisor = 1000000000000000000000000;
        } else if (bytes > 1000000000000000000000) {
          unit = 'ZB';
          divisor = 1000000000000000000000;
        } else if (bytes > 1000000000000000000) {
          unit = 'EB';
          divisor = 1000000000000000000;
        } else if (bytes > 1000000000000000) {
          unit = 'PB';
          divisor = 1000000000000000;
        } else if (bytes > 1000000000000) {
          unit = 'TB';
          divisor = 1000000000000;
        } else if (bytes > 1000000000) {
          unit = 'GB';
          divisor = 1000000000;
        } else if (bytes > 1000000) {
          unit = 'MB';
          divisor = 1000000;
        } else if (bytes > 1000) {
          unit = 'kB';
          divisor = 1000;
        } else {
          unit = 'B';
          divisor = 1;
        }
      } else {
        throw new Error({ 'error': 'invalid base ' + base });
      }

      var result = bytes / divisor;

      if (format)
        return result.toLocaleString(store.state.locale == 'en' ? 'en-GB' : store.state.locale, {
          currencyDisplay: currencyDisplay,
          useGrouping: useGrouping,
          minimumFractionDigits: minimumFractionDigits,
          maximumFractionDigits: maximumFractionDigits,
        }) + ' ' + unit;
      return result + ' ' + unit;
    }
  }
};
